/** @jsx jsx */

import { graphql } from 'gatsby'
import { FC } from 'react'
import { jsx } from 'theme-ui'

import BulletPoints from '../../components/bullet-points'
import Halo from '../../components/halo'
import Intro from '../../components/intro'
import Layout from '../../components/layout'
import SectionWithCards from '../../components/section-with-cards'
import SignUpCallToAction from '../../components/sign-up-call-to-action'
import Video, { VideoProps } from '../../components/video'
import routes from '../../constants/routes'
import { cartaCross, halo, intro, tiers } from '../../content/private-companies-carta-cross'

interface CartaCrossProps {
  data: {
    video: {
      caption: VideoProps['caption']

      file: {
        src: VideoProps['src']
      }
    }

    videoPoster: {
      file: {
        poster: VideoProps['poster']
      }
    }
  }
}

const CartaCross: FC<CartaCrossProps> = ({ data }) => {
  const { caption } = data.video
  const { poster } = data.videoPoster.file
  const { src } = data.video.file

  return (
    <Layout>
      <Halo {...halo} />
      <Intro {...intro} />
      <BulletPoints {...cartaCross} containerStyles={{ py: 4, bg: `gray1_black` }} />
      <Video {...{ caption, poster, src }} />
      <SectionWithCards {...tiers} containerStyles={{ py: 4, bg: `gray2_slate9` }} />
      <SignUpCallToAction to={routes.privateCompaniesRequestInformation} />
    </Layout>
  )
}

export default CartaCross

export const pageQuery = graphql`
  query {
    video: contentfulAsset(file: { contentType: { eq: "video/mp4" } }, title: { eq: "Levi Casey" }) {
      caption: description

      file {
        src: url
      }
    }

    videoPoster: contentfulAsset(file: { contentType: { eq: "image/png" } }, title: { eq: "Levi Casey" }) {
      file {
        poster: url
      }
    }
  }
`
