/** @jsx jsx */

import { Flex, jsx } from 'theme-ui'

import chartIconPath from '../assets/icons/chart.svg'
import chatIconPath from '../assets/icons/chat.svg'
import permissionedAccessIconPath from '../assets/icons/permissioned-access.svg'
import scheduledAuctionsIconPath from '../assets/icons/scheduled-auctions.svg'
import aIconPath from '../assets/icons/small/a.svg'
import cIconPath from '../assets/icons/small/c.svg'
import dIconPath from '../assets/icons/small/d.svg'
import eIconPath from '../assets/icons/small/e.svg'
import standardizedDocumentsIconPath from '../assets/icons/standardized-documents.svg'
import symmetricDisclosuresIconPath from '../assets/icons/symmetric-disclosures.svg'
import Button from '../components/button'
import UniversalLink from '../components/universal-link'
import routes from '../constants/routes'
import theme from '../gatsby-plugin-theme-ui'

export const halo = {
  title: `Carta Cross`,
  route: routes.privateCompaniesCartaCross,
  description: `A spectrum of liquidity, disclosure, and control in a single framework`,
  root: `../../`,
}

export const intro = {
  ...halo,
  secondaryDescription: `High growth issuers can offer programmatic liquidity that scales with the stage of their business, without the distractions of traditional transaction models.`,
}

export const cartaCross = {
  items: [
    {
      icon: scheduledAuctionsIconPath,
      title: `Scheduled auctions`,
      description: `Move away from burdensome, episodic liquidity to programmatic, scheduled auctions.`,
    },
    {
      icon: chartIconPath,
      title: `Market-driven pricing`,
      description: `Bring a diverse set of investors into a competitive market, so your stock price optimally reflects supply and demand.`,
    },
    {
      icon: permissionedAccessIconPath,
      title: `Permissioned access`,
      description: `Identify shareholders and institutional investors that will have access to disclosure information and order entry capabilities.`,
    },
    {
      icon: symmetricDisclosuresIconPath,
      title: `Symmetric disclosures`,
      description: `Facilitate access to disclosures and company-provided KPIs to all permissioned buyers and sellers in a secure data room.`,
    },
    {
      icon: chatIconPath,
      title: `Bilateral transactions`,
      description: `By operating a market in your equity, CartaX eliminates the need for you to sponsor transactions, thus reducing issuer overhead and enabling tax efficiencies for participants.`,
    },
    {
      icon: standardizedDocumentsIconPath,
      title: `Standardized documents`,
      description: (
        <span>
          Crafted in consultation with the{` `}
          <UniversalLink to={routes.partnersLegal}>CartaX Legal Advisory team</UniversalLink>, transaction documents are
          standardized to reduce the overhead of liquidity.
        </span>
      ),
    },
  ],
}

export const cartaXFor = {
  title: `CartaX: Tailored liquidity solutions`,
  description: `From setup to settlement, CartaX powers the full spectrum of private company and private fund transactions.`,
  items: [
    {
      to: routes.privateCompanies,
      title: `Private companies`,
      description: `Choose the right transaction for your stakeholders, including secondary auctions and tender offers`,
    },
    {
      to: routes.institutionalInvestors,
      title: `Institutional investors`,
      description: `Access equity in emerging, innovative businesses and other private market opportunities with issuer-sourced disclosure`,
    },
  ],
}

export const tiers = {
  id: `list-and-trade-on-cartax`,
  title: `List and trade on CartaX`,
  description: `Satisfy certain financial, liquidity, and reporting requirements to list on CartaX and unlock low-touch liquidity.`,
  secondaryDescription: `Liquidity Tiers`,
  items: [
    {
      href: routes.emailInfo,
      id: `growth-tier`,
      description: (
        <Flex sx={{ height: `inherit`, flexDirection: `column` }}>
          <h2 sx={{ mt: 0, mb: 2, color: `primary` }}>Growth Tier</h2>
          <p sx={{ my: 0 }}>Run up to 12 Carta Crosses a year.</p>
          <ul
            sx={{
              borderTop: `1px solid ${theme.colors.grid}`,
              my: 4,
              pt: 4,
              pl: 0,
              flex: 1,
              listStyleType: `none`,
              color: `text`,
            }}
          >
            <Flex as="li" sx={{ my: 0, alignItems: `baseline` }}>
              <img src={cIconPath} alt="" sx={{ pr: 3, position: `relative`, top: `3px` }} />
              <span>Access our diverse investor network to drive competition for your stock</span>
            </Flex>
            <Flex as="li" sx={{ mt: 3, mb: 0, alignItems: `baseline` }}>
              <img src={aIconPath} alt="" sx={{ pr: 3, position: `relative`, top: `3px` }} />
              <span>Standardized low-touch disclosures and performance metrics leveraging Carta data integration</span>
            </Flex>
            <Flex as="li" sx={{ mt: 3, mb: 0, alignItems: `baseline` }}>
              <img src={eIconPath} alt="" sx={{ pr: 3, position: `relative`, top: `3px` }} />
              <span>Configure up to monthly recurring auctions in your stock</span>
            </Flex>
          </ul>
          <Button sx={{ width: `100%` }}>
            Learn more
            <span sx={{ display: [`none`, `inline`] }}>&nbsp;about our Growth Tier</span>
          </Button>
        </Flex>
      ),
    },
    {
      href: routes.emailInfo,
      id: `venture-tier`,
      description: (
        <Flex sx={{ height: `inherit`, flexDirection: `column` }}>
          <h2 sx={{ mt: 0, mb: 2, color: `primary` }}>Venture Tier</h2>
          <p sx={{ my: 0 }}>Run up to four Carta Crosses a year.</p>
          <ul
            sx={{
              borderTop: `1px solid ${theme.colors.grid}`,
              my: 4,
              pt: 4,
              pl: 0,
              flex: 1,
              listStyleType: `none`,
              color: `text`,
            }}
          >
            <Flex as="li" sx={{ my: 0, alignItems: `baseline` }}>
              <img src={dIconPath} alt="" sx={{ pr: 3, position: `relative`, top: `3px` }} />
              <span>Select from a narrow set of existing and new investors</span>
            </Flex>
            <Flex as="li" sx={{ mt: 3, mb: 0, alignItems: `baseline` }}>
              <img src={aIconPath} alt="" sx={{ pr: 3, position: `relative`, top: `3px` }} />
              <span>Standardized low-touch disclosures leveraging Carta data integration</span>
            </Flex>
            <Flex as="li" sx={{ mt: 3, mb: 0, alignItems: `baseline` }}>
              <img src={eIconPath} alt="" sx={{ pr: 3, position: `relative`, top: `3px` }} />
              <span>Configure up to quarterly recurring auctions in your stock</span>
            </Flex>
          </ul>
          <Button sx={{ width: `100%` }}>
            Learn more<span sx={{ display: [`none`, `inline`] }}>&nbsp;about our Venture Tier</span>
          </Button>
        </Flex>
      ),
    },
  ],
}
