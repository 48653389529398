/** @jsx jsx */

import { FC, ReactElement } from 'react'
import { Flex, jsx, useColorMode } from 'theme-ui'

import cardGridBottomPath from '../assets/backgrounds/card-grid-bottom.svg'
import cardGridTopPath from '../assets/backgrounds/card-grid-top.svg'
import rectangularGridPath from '../assets/backgrounds/rectangular-grid.svg'
import rectangularGrid2Path from '../assets/backgrounds/rectangular-grid2.svg'
import UniversalLink from '../components/universal-link'

interface Item {
  to?: string
  href?: string
  title?: string
  description: string | ReactElement
  secondaryDescription?: string | ReactElement
}

interface SectionWithCardsProps {
  containerStyles?: {}
  id?: string
  title: string | ReactElement
  description?: string | ReactElement
  secondaryDescription?: string | ReactElement
  items?: Item[]
  note?: string | ReactElement
}

const SectionWithCards: FC<SectionWithCardsProps> = ({
  containerStyles,
  id,
  title: sectionTitle,
  description: sectionDescription,
  secondaryDescription: sectionSecondaryDescription,
  items,
  note,
}) => {
  const [colorMode] = useColorMode()

  const isDark = false // sectionTitle === `CartaX: Tailored liquidity solutions`
  const isLight = false // sectionTitle === `CartaX: Powered by technology & expertise`
  const isCartaCross = sectionTitle === `List and trade on CartaX`

  return (
    <section {...{ id }} sx={containerStyles}>
      <div sx={{ maxWidth: `var(--maxContentWidth)`, mx: `auto`, px: 4 }}>
        <div sx={{ pt: 4, pb: 3 }}>
          <div sx={{ mx: items ? 0 : `auto`, textAlign: items ? `left` : `center` }}>
            <h2 sx={{ maxWidth: `40ch`, my: 0, fontSize: `2rem` }}>{sectionTitle}</h2>
            {sectionDescription && <p sx={{ maxWidth: `55ch`, mb: 0 }}>{sectionDescription}</p>}
            {sectionSecondaryDescription && (
              <h3
                sx={{
                  mt: 5,
                  mb: 2,
                  borderBottomWidth: 1,
                  borderBottomStyle: `solid`,
                  borderBottomColor: `border`,
                  pb: 3,
                  fontSize: 4,
                  textAlign: `center`,
                }}
              >
                {sectionSecondaryDescription}
              </h3>
            )}
          </div>
        </div>

        {items && (
          <Flex
            as="ul"
            sx={{
              my: 0,
              mx: -2,
              py: 4,
              pl: 0,
              display: [`block`, `block`, `flex`],
              listStyleType: `none`,
              backgroundImage: isCartaCross ? `url(${rectangularGridPath}), url(${rectangularGrid2Path})` : undefined,
              backgroundRepeat: isCartaCross ? `no-repeat, no-repeat` : undefined,
              backgroundPosition: isCartaCross ? `left top, right bottom` : undefined,
            }}
          >
            {items.map(({ to, href, title, description, secondaryDescription }, index) => (
              <Flex key={index} as="li" sx={{ pt: [index ? 3 : 0, index ? 3 : 0, 0], px: 2, flex: 1 }}>
                <Flex
                  as={to || href ? UniversalLink : `div`}
                  sx={{
                    width: `100%`,
                    borderRadius: 6,
                    p: 4,
                    display: `block`,
                    flexDirection: `column`,
                    textDecoration: `none`,
                    bg: `card`,

                    // TODO: Include `prefers-color-scheme` preferences
                    boxShadow: isDark
                      ? `none`
                      : isLight
                      ? `0 2px 8px -2px rgba(0, 0, 0, 0.25)`
                      : colorMode === `dark`
                      ? `none`
                      : `0 2px 8px -2px rgba(0, 0, 0, 0.25)`,

                    transition: `box-shadow 200ms`,
                    color: `inherit`,
                    backgroundImage: isCartaCross
                      ? index
                        ? `url(${cardGridTopPath})`
                        : `url(${cardGridBottomPath})`
                      : undefined,
                    backgroundRepeat: isCartaCross ? `no-repeat` : undefined,
                    backgroundPosition: isCartaCross ? (index ? `right top` : `center bottom`) : undefined,
                    backgroundSize: isCartaCross ? (index ? undefined : `contain`) : undefined,

                    [`:hover, :focus`]: {
                      boxShadow: isDark
                        ? `0 0 6px 2px rgba(255, 255, 255, 0.35)`
                        : isLight
                        ? `0 2px 32px rgba(0, 0, 0, 0.25)`
                        : colorMode === `dark`
                        ? `0 0 6px 2px rgba(255, 255, 255, 0.35)`
                        : `0 2px 32px rgba(0, 0, 0, 0.25)`,
                    },
                  }}
                  {...{ to, href }}
                >
                  {title && (
                    <h2
                      sx={{
                        mt: 0,
                        fontSize: `1.25rem`,
                        color: `primary`,

                        [`a:hover &, a:focus &`]: {
                          textDecoration: secondaryDescription ? `none` : `underline`,
                        },
                      }}
                    >
                      {title}
                    </h2>
                  )}
                  <div sx={{ height: isCartaCross ? `100%` : `auto`, my: 0, flex: 1 }}>{description}</div>
                  {secondaryDescription && (
                    <p sx={{ mb: 0, color: `primary`, [`a:hover &, a:focus &`]: { textDecoration: `underline` } }}>
                      {secondaryDescription}
                    </p>
                  )}
                </Flex>
              </Flex>
            ))}
          </Flex>
        )}

        {note && <p sx={{ my: 0, pb: 4, position: `relative`, lineHeight: 1.125, color: `gray5_slate4` }}>{note}</p>}
      </div>
    </section>
  )
}

export default SectionWithCards
